<template>
	<basePopup :cancelFunc="cancel" :okFunc="save" okText="발급하기" size="lg" title="쿠폰 발급하기">
		<div v-show="!isConfirm">
			<h6>쿠폰 종류</h6>
			<vSelect class="" v-model="input.coupon" :clearable="false" :options="couponOpts" />

			<h6 class="mt-4">쿠폰 유효기간</h6>
			<div class="flex">
				<datePicker placeholder="from" model="input.startDate" />
				<span class="p-2 lh-6">~</span>
				<datePicker placeholder="to" model="input.endDate" />
				<p class="p-2 lh-6">{{ calcDate }}일</p>
			</div>

			<h6 class="mt-4">쿠폰 발급 대상</h6>
			<clipboard :pr="ths" />
		</div>
		<div v-show="isConfirm">
			<h6>
				아래 조건으로 쿠폰을 발급합니다.<br />
				계속 하시겠습니까?
			</h6>
			<vtb :data="inf" oneColumn titleSize="2" />
		</div>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import clipboard from 'comp/local/clipboard'

export default {
	props: { couponOpts: { default: [] }, cName: { default: 'couponAddPopup' } },
	components: { basePopup, clipboard },
	data() {
		return {
			isShow: false,
			isConfirm: false,

			syncData: [],
			input: {
				coupon: '',
				startDate: '',
				endDate: '',
			},

			inf: [
				{ title: '쿠폰 권종', cont: '' },
				{ title: '쿠폰 유효기간', cont: '' },
				{ title: '발급 대상', cont: '' },
			],

			data: {},
		}
	},
	methods: {
		open(item) {
			this.isConfirm = false
			this.input.coupon = this.couponOpts[0]
			this.input.startDate = ''
			this.input.endDate = ''
			setTimeout(() => this.clipboard.clear())

			this.isShow = true
		},
		save() {
			if (!this.isConfirm) {
				const e = []
				if (this.calcDate < 1) e.push('쿠폰 유효기간을 확인해주세요')
				if (this.clipboard.getData.length - 1 < 1) e.push('쿠폰 발급 대상을 확인해주세요')
				if (e.length) alert.w(e)
				else {
					const input = this.input
					this.data = {
						couponSeq: input.coupon.value,
						startDt: input.startDate.get_time('d'),
						endDt: input.endDate.get_time('d'),
						data: this.clipboard.getData,
					}

					this.inf[0].cont = input.coupon.label
					this.inf[1].cont = `${this.data.startDt} ~ ${this.data.endDt} ( ${this.calcDate}일 )`
					this.inf[2].cont = this.data.data.length - 1 + '명'
					this.isConfirm = true
				}
			} else
				postApi('api/backoffice/couponHandle/couponIssuance', this.data).then(res => {
					alert.s('쿠폰이 발급되었습니다')
					this.isShow = false
					reload(this.p)
				})
		},
		cancel() {
			if (!this.isConfirm) {
				if (this.input.file)
					confirm('등록을 완료하지 않은 파일이 있습니다.\r\n등록을 취소하시겠습니까?', () => {
						this.isShow = false
					})
				else this.isShow = false
			} else this.isConfirm = false
		},
	},
	computed: {
		calcDate() {
			const input = this.input
			if (!input.startDate || !input.endDate) return 0
			return (input.endDate.getTime() - input.startDate.getTime()) / 864e5 + 1
		},
		calcMember() {
			return this.clipboard ? this.clipboard.getData.length : 0
		},
	},
}
</script>
