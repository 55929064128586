<template>
	<div>
		<b-form-textarea
			v-show="!origin"
			class="w-1/1 resize-none"
			v-model="origin"
			placeholder="클립보드 붙여넣기( 공백 제외 제일 윗칸은 제목 칸 )"
			@change="paste"
			rows="1"
		/>
		<div v-show="origin" class="overflow-scroll max-h-96 border pr-2 pb-2">
			<table>
				<tr v-for="(line, i) in input">
					<td v-for="j in maxCt">
						<input class="w-44" v-model.trim="input[i][j - 1]" type="text" />
					</td>
				</tr>
			</table>
			<b-button
				class="mt-2"
				@click="
					origin = ''
					input = []
				"
				size="sm"
				variant="gray"
				>다시 붙여넣기
			</b-button>
		</div>
	</div>
</template>

<script>
export default {
	props: { cName: { default: 'clipboard' }, defError: { default: '파일을 업로드해 주세요.' } },
	data() {
		return {
			origin: '',
			maxCt: 0,
			input: [],
			data: [],
		}
	},
	methods: {
		clear() {
			this.origin = ''
			this.maxCt = 0
			this.input = []
			this.data = []
		},

		getPromise(appendData = {}) {
			return new Promise(resolve => {
				resolve({ data: this.getData, ...appendData })
			})
		},

		paste() {
			if (this.origin) {
				let maxCt = 1
				this.input = this.origin.split('\n').map(v => {
					const res = v.split('\t'),
						ct = res.length
					if (ct) {
						if (ct > maxCt) maxCt = ct
						return res
					}
				})
				this.maxCt = maxCt
			}
		},
	},
	computed: {
		e() {
			if (!this.origin) return this.defError
			if (!this.getData.length) return '엑셀 데이터를 확인해주세요'
			return ''
		},
		getData() {
			const res = []
			this.input.map(line => {
				const v = line.map(v => v.trim())
				if (v.filter(v => v).length > 0)
					//행 전체가 빈 값이 아닐 때만 입력
					res.push(v)
			})
			return res
		},
	},
}
</script>
